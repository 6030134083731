import { useSelector, useDispatch } from 'react-redux';
import { setUser, initialState } from 'store/auth/userSlice';
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService';
import { apiGetBusiness } from 'services/BusinessService';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';
import { setBusiness } from 'store/business/businessSlice';

function useAuth() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = useQuery();
    const { token, signedIn } = useSelector((state) => state.auth.session);

    const fetchBusinessData = async (accessToken) => {
        try {
            const businessResp = await apiGetBusiness(accessToken);
            if (businessResp.data) {
                dispatch(setBusiness({ business: businessResp.data.data[0], businesses: businessResp.data.data }));
            } else {
                dispatch(setBusiness({ business: {}, businesses: [] }));
            }
        } catch (error) {
            return { status: 'failed', message: 'Something went wrong!' };
        }
    };

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values);
            if (resp.data) {
                if (resp?.data?.user?.accountStatus === "VERIFIED_USER") {
                    const { accessToken } = resp.data;
                    // Dispatch the token to update the state
                    dispatch(onSignInSuccess(accessToken));

                    if (resp.data.user) {
                        dispatch(setUser(resp.data.user));
                        const role = resp.data.user.roles.map(role => role.name);
                        if (role.includes("ROLE_ADMIN")) {
                            resp.data.user.twoFactorAuthVerified = false;
                            resp.data.user.twoFactorAuth = true;
                        } else {
                            // Fetch business data if not provided in the response
                            await fetchBusinessData(accessToken);
                        }
                        
                    }

                    const roles = resp.data.user.roles;
                    const role = roles.map(role => role.name);
                    const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY) || (role.includes("ROLE_ADMIN") ? "/admin/dashboard" : "/dashboard");
                    localStorage.removeItem(REDIRECT_URL_KEY);
                    navigate(redirectUrl);

                    return { status: 'success', message: 'Success' };
                } else {
                    const { phoneNumber } = resp.data.user;
                    localStorage.setItem("phoneNumber", phoneNumber);
                    navigate("/verify");
                    return { status: 'failed', message: 'Please verify your account' };
                }
            }
        } catch (errors) {
            return { status: 'failed', message: errors?.response?.data?.message || errors.toString() };
        }
    };

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values);
            if (resp.data) {
                const { token } = resp.data;
                dispatch(onSignInSuccess(token));
                if (resp.data.user) {
                    dispatch(setUser(resp.data.user));
                }
                const { phoneNumber } = resp.data.data;
                localStorage.setItem("phoneNumber", phoneNumber);
                navigate("/verify");
                return { status: 'success', message: '' };
            }
        } catch (errors) {
            return { status: 'failed', message: errors?.response?.data?.message || errors.toString() };
        }
    };

    const handleSignOut = () => {
        dispatch(onSignOutSuccess());
        dispatch(setUser(initialState));
        window.location.href = "/";
    };

    const signOut = async () => {
        // await apiSignOut();
        handleSignOut();
    };

    const handleAuthRedirect = () => {
        const redirectUrl = window.location.pathname + window.location.search;
        localStorage.setItem(REDIRECT_URL_KEY, redirectUrl);
        navigate(`/sign-in?redirectUrl=${redirectUrl}`);
    };

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
        handleAuthRedirect,
    };
}

export default useAuth;
