import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    emailOrPhone: '',
    firstName: '',
    lastName: '',
    accountStatus: '',
    accountVerify: '',
    email: '',
    lastLogin: '',
    phoneNumber: '',
    twoFactorAuth: '',
    twoFactorAuthQrCode: '',
    createdAt: '',
    authority: [],
    roles: [],
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.avatar = action.payload.avatar;
            state.emailOrPhone = action.payload.email;
            state.email = action.payload.email;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.accountStatus = action.payload.accountStatus;
            state.accountVerify = action.payload.accountVerify;
            state.lastLogin = action.payload.lastLogin;
            state.phoneNumber = action.payload.phoneNumber;
            state.twoFactorAuthQrCode = action.payload.twoFactorAuthQrCode;
            state.createdAt = action.payload.createdAt;
            state.twoFactorAuth =  action.payload.twoFactorAuth;
            state.twoFactorAuthVerified =  action.payload.twoFactorAuthVerified

            // Extract the roles and set the authority
            const roles = action.payload.roles;
            state.authority = roles.map(role => role.name);
            state.roles = action.payload.roles
        },
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
