import ApiService from './ApiService'

export async function apiGetBusiness(accessToken) {
    return ApiService.fetchData({
        url: '/businessserv/api/v1/business',
        method: 'get',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
}

export async function apiGetBusinessSingle(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/business/${data?.businessId}`,
        method: 'get',
        data,
    })
}
export async function apiGetBusinessWallet(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/business/wallets/${data?.businessId}`,
        method: 'get',
        data,
    })
}
export async function apiGetBusinessPricing(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/business/pricing/${data?.businessId}`,
        method: 'get',
        data,
    })
}
export async function apiChangeBusinessLogo(data) {
    const formData = new FormData();
    formData.append('file', data?.file);

    return ApiService.fetchData({
        url: `/businessserv/api/v1/business/logo/${data?.businessId}`,
        method: 'post',
        data: formData
    })
}
export async function apiGetBusinessApiKeys(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/api/keys/${data?.businessId}`,
        method: 'get',
        data,
    })
}
export async function apiUpdateWebhook(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/business/webhook/${data?.businessId}`,
        method: 'put',
        data,
    })
}
export async function apiGetBusinessTransactions(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/transactions/${data?.businessId}/${data?.page}/${data?.size}/${data?.sort}/${data?.order}`,
        method: 'get',
        data,
    })
}
export async function apiGetBusinessSMSHistory(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/business/sms/history/${data?.businessId}/${data?.page}/${data?.size}/${data?.sort}/${data?.order}`,
        method: 'get',
        data,
    })
}
export async function apiGetBusinessWhatsappHistory(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/whatsapp/${data?.businessId}/${data?.page}/${data?.size}/${data?.sort}/${data?.order}`,
        method: 'get',
        data,
    })
}
export async function apiGetBusinessVoiceHistory(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/otp/call/history/${data?.businessId}/${data?.page}/${data?.size}/${data?.sort}/${data?.order}`,
        method: 'get',
        data,
    })
}
export async function apiGetBusinessSMSSenderId(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/business/sms/senders/${data?.businessId}`,
        method: 'get',
        data,
    })
}
export async function apiSubmitSenderId(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/business/sms/senders`,
        method: 'post',
        data,
    })
}
export async function apiSetupBusiness(data) {
    return ApiService.fetchData({
        url: `/businessserv/api/v1/business/create`,
        method: 'post',
        data,
    })
}
